import * as React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import {StaticImage} from "gatsby-plugin-image"
import {SectionHero, Section, SectionTitle, SectionDescription, SectionPageTitle, SectionImageBkg,  ListPointer, ListItemPointer } from "../components/Section"
// import NeedHelp from "../components/NeedHelp"
import BlueVector from "../images/blue-pattern.jpg"


const SectionBanner = styled(SectionHero)`
	position:relative;
    height: 100%;
    border-bottom:1px solid #ccc;
`
const HeroCaption = styled.div`	
    position:relative;
    z-index:1;
    width: 100%;
    padding:60px 0;
    @media (min-width:1200px){
        padding:80px 0;
    }
    @media (min-width:1600px){
        padding:180px 0;
    }
`

const SectionImageBkgs = styled(SectionImageBkg)`
    width: 50%;
    right:0;
    left:auto;
    &:after {
        display: block;
        background: linear-gradient(to right, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
        @media (min-width:1600px){
            width: 50%;
        }
    }
`

const HeroBackground = styled.div`    
    display: flex;
    align-items: center;
    height: 100%;
    &:after, &:before{
		content: "";
		position: absolute;
        width:100%;
		height:100%;
        top:0;
        left:0;
        clip-path: ellipse(95% 100% at 50% 0);
        @media (min-width: 992px) {
            clip-path: ellipse(95% 100% at 0% 50%);
        }
        @media (min-width: 1440px) {
		    clip-path: ellipse(90% 100% at 0% 50%);
        }
	}
    &:before{
		background: transparent linear-gradient(270deg, #1383C1 0%, #006EAC 100%) 0% 0% no-repeat padding-box;		
	}
	&:after{
		background:${props => props.bgAfter};
		background-repeat: no-repeat;
		background-size: cover;
		opacity: 0.15;
		background-position: center right;
	}	
`

const Grid = styled.div`
    position: relative;
    @media (min-width: 992px) {
        display: grid;
        align-items:center;
        grid-template-columns: 1.5fr 2fr;
    }
`

const GridLeft = styled.div`
    position: relative;    
    filter: drop-shadow(0 0 40px rgba(0, 0, 0, 0.4));    

    padding: 60px 15px;
    @media (min-width: 992px) {
        padding: 90px 60px 90px 30px;
    }
    @media (min-width: 1200px) {
        padding: 80px 60px 80px 30px;
    }
    @media (min-width: 1600px) {
        padding: 220px 60px 220px 30px;
    }
    @media (min-width: 1650px) {
        padding: 220px 60px 220px calc(50vw - 810px);
    }
`
const GridRight = styled.div`
    position: relative;    
    padding: 40px 15px;
    @media (min-width: 992px) {
        padding:40px 30px;  
    }
    @media (min-width: 1650px) {
        padding: 40px calc(50vw - 810px) 40px 30px;  
    }
`

const InsulationPage = ({ location, data }) => {
    return(
        <Layout location={location}>
            <Seo
                title={data.allContentfulPageData.edges[0].node.metaTitle}
                description={data.allContentfulPageData.edges[0].node.metaDescription.metaDescription}
            />
            <SectionBanner className="section-hero">
                <SectionImageBkgs ><StaticImage src="../images/insulation-hero.jpg" alt="insulation-hero" /></SectionImageBkgs>
                <HeroCaption>
                    <div className="container">
                        <SectionPageTitle className="h2" maxWidth="860px" ml="0">Metal Building Insulation</SectionPageTitle>
                        <SectionDescription maxWidth="860px" ml="0" mb="0" fontStyle="italic">
                            <p>When it comes to insulating a metal building, there are many options available. However, not all insulation is created equal. That’s why it’s important to choose the right insulation for your specific needs. At Tubular Building Systems, we offer a 1/4” Astro Foil single bubble insulation. This reflective insulation is perfect for metal buildings. It features a polyethylene bubble that is sandwiched or placed between two protective, strong, metalized polyester film sheets. This insulation is non-toxic and safe, making it a great choice for those who are looking for an environmentally-friendly option.</p>
                        </SectionDescription>
                    </div>
                </HeroCaption>
            </SectionBanner>

            <Section pt="0" pb="0" bgColor="#fff" className="section-value">
                <Grid>
                    <GridLeft>              
                        <HeroBackground bgAfter={`url(${BlueVector})`}>
                            <div>
                                <SectionTitle className="h3" color="#fff" mb="0" ml="0" maxWidth="460px">Benefits of Insulation</SectionTitle> 
                                <ListPointer fontWeight="700" color="#fff" >
                                    <ListItemPointer filter="invert(1)">No Condensation </ListItemPointer>
                                    <ListItemPointer filter="invert(1)">Better Sound Absorption </ListItemPointer>
                                    <ListItemPointer filter="invert(1)">Consistent Temperature</ListItemPointer>
                                    <ListItemPointer filter="invert(1)">Reduced Energy Consumption</ListItemPointer>
                                </ListPointer>
                            </div>
                        </HeroBackground>
                    </GridLeft>

                    <GridRight>
                        <SectionTitle maxWidth="840px">Insulation R-Values for Roof & Walls</SectionTitle>
                        <SectionDescription maxWidth="840px" mb="0" fontStyle="italic">
                            <p>When it comes to insulating your metal building, Astro Foil single bubble insulation is one of the best options on the market. This is because Astro Foil has a great R-value, which measures the material’s ability to resist heat flow. The R-value of Astro Foil is R10 for the roof and R6 for the walls, meaning that the material is better at resisting heat flow through the roof than through the walls. This makes Astro Foil an ideal choice for insulating a building and ensuring that it stays warm in the winter and cool in the summer.</p>
                        </SectionDescription>
                    </GridRight>
                </Grid>
            </Section>
            {/* <NeedHelp /> */}
        </Layout>
    )
}

export default InsulationPage

export const pageQuery = graphql`
  query InsulationPageQuery{
    allContentfulPageData(filter: {page: {eq: "Metal Buildings Insulation"}}) {
      edges {
        node {
          metaTitle
          metaDescription {
            metaDescription
          }
        }
      }
    }
  }
`
